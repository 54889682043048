<template>
  <Main>
    <ToolsPanel
      :breadCrumbFirst="'Templates'"
      :breadCrumbFirstLink="getFirstLink"
      :breadCrumbSecond="'Template Create'"
      :title="'Template Create'"
    >
      <div class="form-create__buttons">
        <MainButton buttonWhite @click.native="handleSubmit">Submit</MainButton>
        <MainButton @click.native="handleBackToList">Back</MainButton>
      </div>
    </ToolsPanel>
    <ContentContainer>
      <div class="form-create">
        <form @submit.prevent="handleSubmit">
          <div class="form-create__form-container">
            <div class="form-create__form-top">
              <div class="form-create__form-main">
                <p class="form-create__label">Name</p>
                <div class="input-container">
                  <InputText
                    :onChange="setName"
                    :id="'name'"
                    :label="'name'"
                    :type="'text'"
                    :value="formDataToSend.name"
                    :placeholder="'Name'"
                    :error-messages="validationErrors['name']"
                    isBordered
                  />
                </div>

                <p class="form-create__label">SEO Name (for URL)</p>
                <div class="input-container">
                  <InputText
                    :onChange="setSeoName"
                    :id="'seo_name'"
                    :label="'seo_name'"
                    :value="formDataToSend.seo_name"
                    :type="'text'"
                    :placeholder="'Seo Name'"
                    :error-messages="validationErrors['seoName']"
                    isBordered
                  />
                </div>

                <div class="input-container">
                  <MainCheckbox :label="'Premium'" :id="'premium'" :set-value="formDataToSend" />
                </div>

                <p class="form-create__label">Preview picture in the product card</p>
                <p v-if="imageSize" class="form-create__label">
                  Preview: {{ imageSize.preview.width }}:{{ imageSize.preview.height }}
                </p>
                <div class="input-container">
                  <div class="form-create__images">
                    <div
                      v-for="(image, index) in formDataToSend.img"
                      :key="image.id"
                      class="form-create__image"
                    >
                      <picture-input
                        v-if="formDataToSend.img[index].file == null"
                        :ref="`pictureInput_${index}`"
                        @change="handleChoosePhoto(index)"
                        accept="image/jpeg,image/png"
                        size="100"
                        width="220"
                        height="220"
                        :name="`image_${index}`"
                        buttonClass="change-image__button"
                        :customStrings="{ drag: '+', change: 'Change' }"
                      />
                      <img
                        v-if="formDataToSend.img[index].file != null"
                        :id="`blah_${index}`"
                        src="#"
                        class="form-create__image-load"
                        alt="load"
                      />
                      <div
                        @click="handleChangeImage(index)"
                        v-if="formDataToSend.img[index].file != null"
                        class="form-create__change-image"
                      >
                        <RestoreIcon />
                      </div>
                      <div
                        v-if="index != 0"
                        @click="handleDeleteImage(index)"
                        class="form-create__delete-image"
                      >
                        <CancelIcon />
                      </div>
                    </div>
                    <div @click="handleAddImage" class="form-create__add-image">
                      <CancelIcon />
                    </div>
                  </div>

                  <Notification :error-messages="validationErrors['images']" />
                </div>

                <p class="form-create__label">
                  Big Preview picture in the product card (from TheGooDocs and ElegantFlyer). <br />
                  *without resizing
                </p>
                <div class="input-container">
                  <div class="form-create__images">
                    <div
                      v-for="(image, index) in formDataToSend.preview"
                      :key="image.id"
                      class="form-create__image"
                    >
                      <picture-input
                        v-if="formDataToSend.preview[index].file == null"
                        :ref="`previewInput_${index}`"
                        @change="handleChoosePreview(index)"
                        accept="image/jpeg,image/png"
                        size="100"
                        width="220"
                        height="220"
                        :name="`preview_${index}`"
                        buttonClass="change-image__button"
                        :customStrings="{ drag: '+', change: 'Change' }"
                      />
                      <img
                        v-if="formDataToSend.preview[index].file != null"
                        :id="`blah-preview_${index}`"
                        src="#"
                        class="form-create__image-load"
                        alt="load"
                      />
                      <div
                        @click="handleChangePreview(index)"
                        v-if="formDataToSend.preview[index].file != null"
                        class="form-create__change-image"
                      >
                        <RestoreIcon />
                      </div>
                      <div
                        v-if="index != 0"
                        @click="handleDeletePreview(index)"
                        class="form-create__delete-image"
                      >
                        <CancelIcon />
                      </div>
                    </div>
                    <div @click="handleAddImagePreview" class="form-create__add-image">
                      <CancelIcon />
                    </div>
                  </div>

                  <Notification :error-messages="validationErrors['preview']" />
                </div>

                <p class="form-create__label">Download GIF</p>
                <div class="input-container">
                  <div class="form-create__images">
                    <div
                      v-for="(image, index) in formDataToSend.gif"
                      :key="image.id"
                      class="form-create__image"
                    >
                      <picture-input
                        v-if="formDataToSend.gif[index].file == null"
                        :ref="`gifInput_${index}`"
                        @change="handleChooseGif(index)"
                        accept="image/gif"
                        size="100"
                        width="220"
                        height="220"
                        :name="`gif_${index}`"
                        buttonClass="change-image__button"
                        :customStrings="{ drag: '+', change: 'Change' }"
                      />
                      <img
                        v-if="formDataToSend.gif[index].file != null"
                        :id="`blah-gif_${index}`"
                        src="#"
                        class="form-create__image-load"
                        alt="load"
                      />
                      <div
                        @click="handleChangeGif(index)"
                        v-if="formDataToSend.gif[index].file != null"
                        class="form-create__change-image"
                      >
                        <RestoreIcon />
                      </div>
                      <div
                        v-if="index != 0"
                        @click="handleDeleteGif(index)"
                        class="form-create__delete-image"
                      >
                        <CancelIcon />
                      </div>
                    </div>
                    <div @click="handleAddImageGif" class="form-create__add-image">
                      <CancelIcon />
                    </div>
                  </div>

                  <Notification :error-messages="validationErrors['gif']" />
                </div>

                <p class="form-create__label">
                  Download Slider Images (Template page. From ElegantFlyer)
                </p>
                <div class="input-container">
                  <div class="form-create__images">
                    <div
                      v-for="(image, index) in formDataToSend.slider"
                      :key="image.id"
                      class="form-create__image"
                    >
                      <picture-input
                        v-if="formDataToSend.slider[index].file == null"
                        :ref="`gifInput_${index}`"
                        @change="handleChooseSlider(index)"
                        accept="image/jpeg,image/png"
                        size="100"
                        width="220"
                        height="220"
                        :name="`slider_${index}`"
                        buttonClass="change-image__button"
                        :customStrings="{ drag: '+', change: 'Change' }"
                      />
                      <img
                        v-if="formDataToSend.slider[index].file != null"
                        :id="`blah-slider_${index}`"
                        src="#"
                        class="form-create__image-load"
                        alt="load"
                      />
                      <div
                        @click="handleChangeSlider(index)"
                        v-if="formDataToSend.slider[index].file != null"
                        class="form-create__change-image"
                      >
                        <RestoreIcon />
                      </div>
                      <div
                        v-if="index != 0"
                        @click="handleDeleteSlider(index)"
                        class="form-create__delete-image"
                      >
                        <CancelIcon />
                      </div>
                    </div>
                    <div @click="handleAddImageSlider" class="form-create__add-image">
                      <CancelIcon />
                    </div>
                  </div>

                  <Notification :error-messages="validationErrors['sliderImages']" />
                </div>

                <p class="form-create__label">Download video</p>
                <p v-if="successUploadVideo" class="form-create__label">Successful video upload</p>
                <div class="input-container upload-instruction">
                  <input
                    @change="handleLoadVideo"
                    type="file"
                    name="video"
                    id="video"
                    accept="*,.mp3,.mp4,.mov,.ogg"
                  />
                  <label
                    v-if="formDataToSend.video == null"
                    class="upload-instruction__button"
                    for="video"
                    >Load</label
                  >
                  <label v-else class="upload-instruction__button" for="video">Change</label>
                  <label
                    v-if="formDataToSend.videoUrl != null"
                    class="upload-instruction__button"
                    @click="handleDeleteVideo"
                    >Delete</label
                  >
                  <Notification :error-messages="validationErrors['video']" />
                </div>

                <p class="form-create__label">Annotation</p>
                <div class="input-container">
                  <TextEditor
                    :set-value="formDataToSend"
                    :id="'annotation'"
                    :placeholder="''"
                    :error-messages="validationErrors['annotation']"
                  />
                </div>
              </div>
              <div class="form-create__form-said">
                <p class="form-create__label">Status</p>
                <div class="input-container">
                  <MainSelect
                    :id="'status'"
                    :placeholder="'Choose'"
                    :option-list="templatesStatusesList"
                    :set-value="formDataToSend"
                    :value="formDataToSend.status"
                    :error-messages="validationErrors['status']"
                    :disabled="!isAdmin"
                  />
                  <Notification :error-messages="validationErrors['statusId']" />
                </div>

                <p class="form-create__label">Categories</p>
                <div
                  v-if="getSiteID != 5"
                  class="input-container checkbox-container checkbox-container_margin"
                >
                  <InputCategoryTemplate
                    :set-value="formDataToSend"
                    :id="'categories'"
                    :id-level="'categoriesLevel'"
                    :parent="'categoriesParent'"
                    :values="categoriesTree"
                    :name="'name'"
                    :value="'id'"
                    :handle-search="handleSearch"
                    :search-trigger="searchTrigger"
                  />
                </div>
                <div v-else class="input-container f checkbox-container checkbox-container_margin">
                  <InputCategory
                    :set-value="formDataToSend"
                    :id="'categories'"
                    :values="categoriesTree"
                    :name="'name'"
                    :value="'id'"
                  />
                </div>
                <Notification :error-messages="validationErrors['categories']" />

                <p class="form-create__label">Template Types</p>
                <div class="input-container checkbox-container">
                  <InputRadio
                    :set-value="formDataToSend"
                    :id="'types'"
                    :values="templatesTypesList"
                    :name="'name'"
                    :value="'id'"
                  />
                  <Notification :error-messages="validationErrors['typeId']" />
                </div>

                <p class="form-create__label">Template Styles</p>
                <div class="input-container checkbox-container">
                  <InputRadio
                    :set-value="formDataToSend"
                    :id="'style'"
                    :values="templatesStyleList"
                    :name="'name'"
                    :value="'id'"
                  />
                  <Notification :error-messages="validationErrors['styleId']" />
                </div>

                <p class="form-create__label">Template Colors</p>
                <div class="input-container checkbox-container">
                  <div
                    v-for="item in templatesColorList"
                    :key="item.id"
                    class="checkbox-container__item"
                  >
                    <MainCheckbox
                      :label="item.name"
                      :id="item.id + ''"
                      :set-value="formDataToSend.color"
                    />
                  </div>
                </div>

                <p class="form-create__label">Template Formats</p>
                <div class="input-container checkbox-container">
                  <div
                    v-for="item in templatesFormatsList"
                    :key="item.id"
                    class="checkbox-container__item"
                  >
                    <MainCheckbox
                      :label="item.name"
                      :id="item.id + ''"
                      :set-value="formDataToSend.formats"
                    />
                  </div>
                </div>

                <p class="form-create__label">Template Tags</p>
                <div class="input-container checkbox-container">
                  <div
                    v-for="item in templatesTagsList"
                    :key="item.id"
                    class="checkbox-container__item"
                  >
                    <MainCheckbox
                      :label="item.name"
                      :id="item.id + ''"
                      :set-value="formDataToSend.tags"
                    />
                  </div>
                </div>

                <p class="form-create__label">Template Fonts</p>
                <div class="input-container">
                  <InputSearch
                    :search="getFontsFilter"
                    :id="'search'"
                    :label="'search'"
                    :placeholder="'search...'"
                  />
                </div>
                <div class="input-container checkbox-container">
                  <div
                    v-for="item in templatesFontsList"
                    :key="item.id"
                    class="checkbox-container__item"
                  >
                    <div :title="item.url" class="form-create__row-item">
                      {{ item.name }}
                      <div @click="addFont(item)" class="form-create__row-add"><CancelIcon /></div>
                    </div>
                  </div>
                </div>

                <p class="form-create__label">Selected Fonts</p>
                <div
                  v-if="formDataToSend.fonts.length > 0"
                  class="input-container checkbox-container"
                >
                  <div
                    v-for="(item, index) in formDataToSend.fonts"
                    :key="item.id"
                    class="checkbox-container__item"
                  >
                    <div :title="item.url" class="form-create__row-item">
                      {{ item.name }}
                      <div @click="deleteFont(index)" class="form-create__row-delete">
                        <CancelIcon />
                      </div>
                    </div>
                  </div>
                </div>
                <p v-else class="input-container">No Chosen Fonts...</p>
                <p class="form-create__label">Instruction</p>
                <div class="input-container upload-instruction">
                  <input
                    @change="handleLoadInstruction"
                    type="file"
                    name="instruction"
                    id="instruction"
                    accept="*,.pdf"
                  />
                  <label
                    v-if="formDataToSend.file == null"
                    class="upload-instruction__button"
                    for="instruction"
                    >Load</label
                  >
                  <label v-else class="upload-instruction__button" for="instruction">Change</label>
                  <label
                    v-if="formDataToSend.fileUrl != null"
                    class="upload-instruction__button"
                    @click="handleDeleteInstruction"
                    >Delete</label
                  >
                </div>
              </div>
            </div>
            <div class="form-create__from-bottom">
              <p class="form-create__label">
                File name to FTP (ATTENTION! Fill only when you want add the archive file)
                <br />
                For ElegantFlyer*
              </p>
              <div class="input-container form-create__file-ftp">
                <InputText
                  :set-value="formDataToSend"
                  :id="'fileToFTP'"
                  :label="'fileToFTP'"
                  :type="'text'"
                  :placeholder="'File name to FTP'"
                  :error-messages="validationErrors['fileName']"
                  isBordered
                />
              </div>
              <div v-if="!formDataToSend.file_name.length" class="form-create__btn">
                <MainButton
                  :buttonDisabled="
                    formDataToSend.fileToFTP.length && formDataToSend.seo_name.length ? false : true
                  "
                  @click.native="transferFile"
                  >Load to DO</MainButton
                >
              </div>
              <p v-if="formDataToSend.file_name.length" class="form-create__label">
                File name to DO: {{ formDataToSend.file_name }}
              </p>
              <p class="form-create__label">Youtube video</p>
              <div class="form-create__sizes">
                <div class="form-create__sizes-item">
                  <p class="form-create__label">Title video</p>
                </div>
                <div class="form-create__sizes-item">
                  <p class="form-create__label">Video code</p>
                </div>
              </div>
              <div
                v-for="(item, index) in formDataToSend.videos"
                :key="item.id"
                class="form-create__sizes"
              >
                <div class="form-create__sizes-item">
                  <InputText
                    :set-value="formDataToSend.videos[index]"
                    :id="'title'"
                    :label="'title'"
                    :type="'text'"
                    :placeholder="''"
                    isBordered
                  />
                </div>
                <div class="form-create__sizes-item">
                  <InputText
                    :set-value="formDataToSend.videos[index]"
                    :id="'url'"
                    :label="'url'"
                    :type="'text'"
                    :placeholder="''"
                    isBordered
                  />
                </div>
                <div
                  v-if="index != 0"
                  @click="handleDeleteRowVideo(index)"
                  class="form-create__sizes-delete"
                >
                  <CancelIcon />
                </div>
              </div>
              <div class="form-create__add-size">
                <MainButton @click.native="handleAddRowVideo">Add more videos</MainButton>
              </div>

              <p class="form-create__label">Template sources</p>
              <div class="form-create__sizes">
                <div class="form-create__sizes-item">
                  <p class="form-create__label">Name source</p>
                </div>
                <div class="form-create__sizes-item">
                  <p class="form-create__label">Source Size</p>
                </div>
                <div class="form-create__sizes-item">
                  <p class="form-create__label">SRC for google drive</p>
                </div>
                <div class="form-create__sizes-item">
                  <p class="form-create__label">Load File in DO</p>
                </div>
              </div>
              <div v-for="(item, index) in formDataToSend.sizes" :key="index">
                <div class="form-create__sizes">
                  <div class="form-create__sizes-item">
                    <InputText
                      :set-value="formDataToSend.sizes[index]"
                      :id="'name'"
                      :label="'name'"
                      :type="'text'"
                      :placeholder="''"
                      isBordered
                    />
                  </div>
                  <div class="form-create__sizes-item">
                    <MainSelect
                      :id="'size'"
                      :placeholder="'Choose'"
                      :option-list="templatesSizesList"
                      :set-value="formDataToSend.sizes[index]"
                      :value="formDataToSend.sizes[index].size"
                    />
                  </div>
                  <div class="form-create__sizes-item">
                    <InputText
                      :set-value="formDataToSend.sizes[index]"
                      :id="'src'"
                      :label="'src'"
                      :type="'text'"
                      :placeholder="''"
                      isBordered
                    />
                  </div>
                  <div class="form-create__sizes-item">
                    <div
                      class="form-create__sizes-item-file"
                      v-if="formDataToSend.sizes[index].file_name != null"
                    >
                      {{ formDataToSend.sizes[index].file_name }}
                    </div>
                    <div class="upload-instruction">
                      <input
                        @change="handleLoadSourceFile(index)"
                        type="file"
                        :name="`source_file_${index}`"
                        :id="`source_file_${index}`"
                        accept=".docs, .docx, .doc, .ppt, .xls, .xlsx, .pptx"
                      />
                      <label
                        v-if="formDataToSend.sizes[index].file == null"
                        class="upload-instruction__button"
                        :for="`source_file_${index}`"
                        >Load</label
                      >
                      <label v-else class="upload-instruction__button" :for="`source_file_${index}`"
                        >Change</label
                      >
                      <label
                        v-if="formDataToSend.sizes[index].file_name != null"
                        class="upload-instruction__button"
                        @click="handleDeleteSourceFile(index)"
                        >Delete</label
                      >
                    </div>
                  </div>
                  <div
                    v-if="index != 0"
                    @click="handleDeleteRow(index)"
                    class="form-create__sizes-delete"
                  >
                    <CancelIcon />
                  </div>
                </div>
                <Notification :error-messages="validationErrors[`sources${index}FileName`]" />
                <Notification :error-messages="validationErrors[`sources${index}Name`]" />
                <Notification :error-messages="validationErrors[`sources${index}SizeId`]" />
                <Notification :error-messages="validationErrors[`sources${index}Source`]" />
              </div>

              <div v-if="error.message != undefined" class="form-create__error">
                {{ error.message }}
              </div>
              <div class="form-create__add-size">
                <MainButton @click.native="handleAddRow">Add source</MainButton>
              </div>

              <div class="form-create__btn">
                <MainButton :type="'submit'">Submit</MainButton>
              </div>
            </div>
          </div>
        </form>
        <ModalError
          :error-modal="false"
          :handle-close-error-modal="
            () => {
              this.error = {};
            }
          "
        />
      </div>
      <MainModal
        v-if="errorModal"
        :onClose="
          () => {
            errorModal = false;
          }
        "
        modalCenter
      >
        <div class="error-img">{{ errorModalText }}</div></MainModal
      >
    </ContentContainer>
    <FetchSpinnerModal v-if="loadingProcess" />
  </Main>
</template>

<script>
import PictureInput from "vue-picture-input";
// import DatePicker from "vue2-datepicker";
import moment from "moment";
import ROUTE from "~/constants/routes";
import referencesApi from "~/api/references";
import templatesApi from "~/api/templates";
import { mapState } from "vuex";
import { seoTransform } from "~/helpers/seoTransform";

import "vue2-datepicker/index.css";

import Main from "~/templates/Main.vue";
import FetchSpinnerModal from "~/components/molecules/FetchSpinnerModal.vue";
import ToolsPanel from "~/components/molecules/ToolsPanel.vue";
import ContentContainer from "~/components/molecules/ContentContainer.vue";
import MainButton from "~/components/atoms/buttons/MainButton.vue";
import MainModal from "~/components/molecules/MainModal.vue";
import InputText from "~/components/atoms/inputs/InputText.vue";
import MainCheckbox from "~/components/atoms/inputs/MainCheckbox.vue";
import ModalError from "~/components/molecules/ModalError.vue";
import MainSelect from "~/components/atoms/inputs/MainSelect.vue";
import InputRadio from "~/components/atoms/inputs/InputRadio.vue";
import Notification from "~/components/molecules/Notification.vue";
import InputCategoryTemplate from "~/components/atoms/inputs/InputCategoryTemplate.vue";
import InputCategory from "~/components/atoms/inputs/InputCategory.vue";
import CancelIcon from "~/assets/images/icons/cancel-icon.svg";
import RestoreIcon from "~/assets/images/icons/restotre.svg";
import TextEditor from "~/components/atoms/inputs/TextEditor.vue";
import InputSearch from "~/components/atoms/inputs/InputSearch.vue";
import { getSite } from "~/helpers/userAuth";
import { getStoredUserInfo } from "~/helpers/userAuth";

export default {
  name: "TemplateCreate",
  metaInfo: {
    title: "Template Create",
  },
  data() {
    return {
      isAdmin: false,
      isDesigner: false,
      search: "",
      searchTrigger: false,
      successUploadVideo: false,
      formDataToSend: {
        annotation: "",
        status: null,
        premium: false,
        categories: null,
        categoriesLevel: [],
        categoriesParent: null,
        types: null,
        style: null,
        formats: {},
        color: {},
        tags: {},
        fonts: [],
        name: "",
        seo_name: "",
        fileToFTP: "",
        file_name: "",
        sizes: [
          {
            name: "",
            size: null,
            size_id: null,
            src: "",
            file: null,
            file_name: null,
          },
        ],
        videos: [
          {
            title: "",
            url: "",
            id: 3234234234,
          },
        ],
        date: null,
        img: [
          {
            id: 0,
            file: null,
          },
        ],
        imgUrl: [
          {
            url: null,
          },
        ],
        gif: [
          {
            id: 0,
            file: null,
          },
        ],
        gifUrl: [
          {
            url: null,
          },
        ],
        preview: [
          {
            id: 0,
            file: null,
          },
        ],
        previewUrl: [
          {
            url: null,
          },
        ],
        slider: [
          {
            id: 0,
            file: null,
          },
        ],
        sliderUrl: [
          {
            url: null,
          },
        ],
        file: null,
        fileUrl: null,
        video: null,
        videoUrl: null,
      },
      errorModal: false,
      errorModalText: "",
      imageSize: null,
    };
  },
  components: {
    MainButton,
    InputText,
    PictureInput,
    MainCheckbox,
    ModalError,
    MainSelect,
    InputRadio,
    // DatePicker,
    Notification,
    InputCategoryTemplate,
    InputCategory,
    Main,
    FetchSpinnerModal,
    ToolsPanel,
    ContentContainer,
    CancelIcon,
    RestoreIcon,
    TextEditor,
    MainModal,
    InputSearch,
  },
  mounted() {
    if (getStoredUserInfo()) {
      const user = getStoredUserInfo();
      if (user.groups[0].id == 1 || user.groups[0].id == 2) {
        this.isAdmin = true;
      }

      if (!this.isAdmin) {
        this.formDataToSend.status = {
          id: 2,
          name: "Waiting for approval",
        };
      }
    }
    this.getCategoriesList();
    this.getStatuses();
    this.getTypes();
    this.getFormats();
    this.getTags();
    this.getImageSizes();
    this.getStyle();
    this.getColor();
    this.getSizes();
  },
  computed: {
    ...mapState("templates", ["error", "loadingProcess", "validationErrors"]),
    ...mapState("categories", ["categoriesTree"]),
    ...mapState("references", [
      "templatesStatusesList",
      "templatesTypesList",
      "templatesFormatsList",
      "templatesTagsList",
      "templatesFontsList",
      "templatesStyleList",
      "templatesColorList",
      "templatesSizesList",
    ]),
    getFirstLink() {
      return ROUTE.TEMPLATES_LIST.replace(":page", 1);
    },
    getSiteID() {
      return getSite().id;
    },
  },
  methods: {
    async handleSubmit() {
      const data = {
        name: this.formDataToSend.name,
        annotation: this.formDataToSend.annotation,
        seo_name: this.formDataToSend.seo_name,
        premium: this.formDataToSend.premium,
        images: [],
        // created_by: this.user.id,
      };

      if (this.formDataToSend.file_name.length) {
        data.file_name = this.formDataToSend.file_name;
      }

      let sliderImages = [];
      if (this.formDataToSend.sliderUrl.length > 0) {
        const arraySlider = this.formDataToSend.sliderUrl;
        for (let i = 0; arraySlider.length > i; i++) {
          if (arraySlider[i].imageId != null) {
            sliderImages.push(arraySlider[i].imageId);
          }
        }
      }
      if (sliderImages.length > 0) {
        data.slide_images = sliderImages;
      }

      let images = [];
      if (this.formDataToSend.imgUrl.length > 0) {
        const arrayImages = this.formDataToSend.imgUrl;
        for (let i = 0; arrayImages.length > i; i++) {
          if (arrayImages[i].imageId != null) {
            images.push(arrayImages[i].imageId);
          }
        }
      }
      if (images.length > 0) {
        data.images = images;
      }

      if (this.formDataToSend.gifUrl.length > 0) {
        const arrayGif = this.formDataToSend.gifUrl;
        for (let i = 0; arrayGif.length > i; i++) {
          if (arrayGif[i].url != null) {
            data.images.push(arrayGif[i].gifId);
          }
        }
      }

      if (this.formDataToSend.previewUrl.length > 0) {
        const arrayPreview = this.formDataToSend.previewUrl;
        for (let i = 0; arrayPreview.length > i; i++) {
          if (arrayPreview[i].url != null) {
            data.images.push(arrayPreview[i].imageId);
          }
        }
      }

      if (this.formDataToSend.fileUrl != null) {
        data.instruction = this.formDataToSend.fileUrl;
      }
      if (this.formDataToSend.videoUrl != null) {
        data.video = this.formDataToSend.videoUrl;
      }
      if (this.formDataToSend.status != null) {
        if (this.isAdmin) {
          data.status_id = this.formDataToSend.status.id;
        } else {
          data.status_id = 2;
        }
      }
      let user_id = null;
      if (getStoredUserInfo()) {
        const user = getStoredUserInfo();
        user_id = user.id;
      }
      data.history = [
        {
          comment: "Create",
          created_by: user_id,
          status_id: data.status_id,
        },
      ];
      if (this.formDataToSend.status != null) {
        if (
          +this.formDataToSend.status.id === 3 &&
          (this.formDataToSend.date == null || this.formDataToSend.date === "Invalid date")
        ) {
          this.$store.commit("templates/setValidationErrors", {
            time: ["Fill in the time field!"],
          });
          return false;
        }
      }

      // if (this.formDataToSend.date != null) {
      //   data.published_at = moment(this.formDataToSend.date, "DD-MM-YYYY HH:mm:ss").format(
      //     "YYYY-MM-DD HH:mm:ss",
      //   );
      // } else {
      //   data.published_at = moment().format("YYYY-MM-DD HH:mm:ss");
      // }

      let categoriesSend = [];
      if (this.formDataToSend.categories != null) {
        categoriesSend.push(this.formDataToSend.categories);
      } else if (this.formDataToSend.categoriesLevel.length > 0) {
        categoriesSend = this.formDataToSend.categoriesLevel;
      }
      if (categoriesSend.length > 0) {
        data.categories = categoriesSend;
      }

      const formats = Object.keys(this.formDataToSend.formats);
      if (formats.length) {
        let formatsSend = [];
        for (let i = 0; formats.length > i; i++) {
          if (this.formDataToSend.formats[formats[i]] == true) {
            formatsSend.push(formats[i]);
          }
        }
        if (formatsSend.length > 0) {
          data.formats = formatsSend;
        }
      }

      const color = Object.keys(this.formDataToSend.color);
      if (color.length) {
        let colorSend = [];
        for (let i = 0; color.length > i; i++) {
          if (this.formDataToSend.color[color[i]] == true) {
            colorSend.push(color[i]);
          }
        }
        if (colorSend.length > 0) {
          data.colors = colorSend;
        }
      }

      const tags = Object.keys(this.formDataToSend.tags);
      if (tags.length) {
        let tagsSend = [];
        for (let i = 0; tags.length > i; i++) {
          if (this.formDataToSend.tags[tags[i]] == true) {
            tagsSend.push(tags[i]);
          }
        }
        if (tagsSend.length > 0) {
          data.tags = tagsSend;
        }
      }

      const fonts = this.formDataToSend.fonts;
      const fontsSend = [];
      if (fonts.length > 0) {
        for (let i = 0; fonts.length > i; i++) {
          fontsSend.push(fonts[i].id);
        }
      }

      if (fontsSend.length > 0) {
        data.fonts = fontsSend;
      }

      if (this.formDataToSend.types != null) {
        data.type_id = this.formDataToSend.types;
      }

      if (this.formDataToSend.style != null) {
        data.style_id = this.formDataToSend.style;
      }

      const sizesSend = [];
      const sizes = this.formDataToSend.sizes;
      for (let i = 0; sizes.length > i; i++) {
        if (sizes[i].size != null || sizes[i].name.length) {
          sizesSend.push({
            name: sizes[i].name,
            source: sizes[i].src,
            size_id: sizes[i]?.size?.id || null,
            file_name: sizes[i].file_name,
          });
        }
      }
      if (this.formDataToSend.sizes.length > 0) {
        data.sources = sizesSend;
      }

      const videosSend = [];
      const videos = this.formDataToSend.videos;
      for (let i = 0; videos.length > i; i++) {
        if (videos[i].url.length > 0) {
          videosSend.push(videos[i]);
        }
      }
      if (this.formDataToSend.videos.length > 0) {
        data.videos = videosSend;
      }

      this.$store.dispatch("templates/createTemplate", data);
    },

    transferFile() {
      this.$store.commit("templates/setLoading", true);
      templatesApi
        .transferFile({
          fileName: this.formDataToSend.fileToFTP,
          seo_name: this.formDataToSend.seo_name,
        })
        .then((res) => {
          if (res.success) {
            this.$store.commit("templates/setError", {});
            this.$store.commit("templates/setValidationErrors", {});
            this.formDataToSend.file_name = res.data.link;
          }
          this.$store.commit("templates/setLoading", false);
        })
        .catch((error) => {
          const { data } = error.response;
          this.$store.commit("templates/setError", data);
          if (data.errors) {
            this.$store.commit("templates/setValidationErrors", data.errors);
          }
          this.$store.commit("templates/setLoading", false);
        });
    },

    addFont(item) {
      if (!this.formDataToSend.fonts.find((font) => font.id === item.id)) {
        this.formDataToSend.fonts.push(item);
      }
    },

    deleteFont(index) {
      this.formDataToSend.fonts.splice(index, 1);
    },

    handleChoosePhoto(index) {
      setTimeout(() => {
        this.formDataToSend.img[index].file = document.querySelector(
          `input[name="image_${index}"]`,
        ).files[0];
        const reader = new FileReader();

        reader.onload = function (e) {
          document.getElementById(`blah_${index}`).setAttribute("src", e.target.result);
        };

        reader.readAsDataURL(this.formDataToSend.img[index].file);

        this.$store.commit("templates/setLoading", true);
        const data = new FormData();
        data.append("image", this.formDataToSend.img[index].file);
        templatesApi
          .templateImagesUpload(data)
          .then((res) => {
            this.formDataToSend.imgUrl[index].url = Object.values(res.data)[0].fileName;
            this.formDataToSend.imgUrl[index].imageId = Object.values(res.data)[0].imageId;
          })
          .catch((error) => {
            this.formDataToSend.img[index].file = null;
            this.errorModal = true;
            this.errorModalText = "You can't upload images. Error";
          })
          .finally(() => {
            this.$store.commit("templates/setLoading", false);
          });
      }, 0);
    },

    handleChooseGif(index) {
      setTimeout(() => {
        this.formDataToSend.gif[index].file = document.querySelector(
          `input[name="gif_${index}"]`,
        ).files[0];
        const reader = new FileReader();

        reader.onload = function (e) {
          document.getElementById(`blah-gif_${index}`).setAttribute("src", e.target.result);
        };

        reader.readAsDataURL(this.formDataToSend.gif[index].file);

        this.$store.commit("templates/setLoading", true);
        const data = new FormData();
        data.append("image", this.formDataToSend.gif[index].file);
        templatesApi
          .templateImagesUpload(data)
          .then((res) => {
            console.log(res.data);
            this.formDataToSend.gifUrl[index].url = Object.values(res.data)[0].fileName;
            this.formDataToSend.gifUrl[index].gifId = Object.values(res.data)[0].imageId;
          })
          .catch((error) => {
            this.formDataToSend.gif[index].file = null;
            this.errorModal = true;
            this.errorModalText = "You can't upload images. Error";
          })
          .finally(() => {
            this.$store.commit("templates/setLoading", false);
          });
      }, 0);
    },

    handleChoosePreview(index) {
      setTimeout(() => {
        this.formDataToSend.preview[index].file = document.querySelector(
          `input[name="preview_${index}"]`,
        ).files[0];
        const reader = new FileReader();

        reader.onload = function (e) {
          document.getElementById(`blah-preview_${index}`).setAttribute("src", e.target.result);
        };

        reader.readAsDataURL(this.formDataToSend.preview[index].file);

        this.$store.commit("templates/setLoading", true);
        const data = new FormData();
        data.append("image", this.formDataToSend.preview[index].file);
        templatesApi
          .templateImagesPreviewUpload(data)
          .then((res) => {
            console.log(res.data);
            data.append("image", this.formDataToSend.preview[index].file);
            this.formDataToSend.previewUrl[index].url = Object.values(res.data)[0].fileName;
            this.formDataToSend.previewUrl[index].imageId = Object.values(res.data)[0].imageId;
          })
          .catch((error) => {
            this.formDataToSend.preview[index].file = null;
            this.errorModal = true;
            this.errorModalText = "You can't upload images. Error";
          })
          .finally(() => {
            this.$store.commit("templates/setLoading", false);
          });
      }, 0);
    },

    handleChooseSlider(index) {
      setTimeout(() => {
        this.formDataToSend.slider[index].file = document.querySelector(
          `input[name="slider_${index}"]`,
        ).files[0];
        const reader = new FileReader();

        reader.onload = function (e) {
          document.getElementById(`blah-slider_${index}`).setAttribute("src", e.target.result);
        };

        reader.readAsDataURL(this.formDataToSend.slider[index].file);

        this.$store.commit("templates/setLoading", true);
        const data = new FormData();
        data.append("image", this.formDataToSend.slider[index].file);
        templatesApi
          .templateImagesSliderUpload(data)
          .then((res) => {
            console.log(res.data);
            data.append("image", this.formDataToSend.slider[index].file);
            this.formDataToSend.sliderUrl[index].url = Object.values(res.data)[0].fileName;
            this.formDataToSend.sliderUrl[index].imageId = Object.values(res.data)[0].imageId;
          })
          .catch((error) => {
            this.formDataToSend.slider[index].file = null;
            this.errorModal = true;
            this.errorModalText = "You can't upload images. Error";
          })
          .finally(() => {
            this.$store.commit("templates/setLoading", false);
          });
      }, 0);
    },

    handleLoadInstruction() {
      if (this.formDataToSend.fileUrl != null) {
        this.handleDeleteInstruction();
      }
      this.formDataToSend.file = document.querySelector(`input[name="instruction"]`).files[0];
      let data = new FormData();
      data.append("instruction", this.formDataToSend.file);
      this.$store.commit("templates/setLoading", true);
      templatesApi
        .templateInstructionUpload(data)
        .then((res) => {
          this.formDataToSend.fileUrl = res.data.fileName;
        })
        .catch((error) => {
          console.log(error);
          this.formDataToSend.file = null;
          this.formDataToSend.fileUrl = null;
          this.errorModal = true;
          this.errorModalText = "You can't upload PDF. Error";
        })
        .finally(() => {
          this.$store.commit("templates/setLoading", false);
        });
    },

    handleLoadSourceFile(index) {
      if (this.formDataToSend.sizes[index].file_name != null) {
        this.handleDeleteSourceFile(index);
      }
      this.formDataToSend.sizes[index].file = document.querySelector(
        `input[name="source_file_${index}"]`,
      ).files[0];
      let data = new FormData();
      data.append("file", this.formDataToSend.sizes[index].file);
      this.$store.commit("templates/setLoading", true);
      templatesApi
        .templateSourceFileUpload(data)
        .then((res) => {
          this.formDataToSend.sizes[index].file_name = res.data.path;
        })
        .catch((error) => {
          console.log(error);
          this.formDataToSend.sizes[index].file = null;
          this.formDataToSend.sizes[index].file_name = null;
          this.errorModal = true;
          this.errorModalText = "You can't upload Source File. Error";
        })
        .finally(() => {
          this.$store.commit("templates/setLoading", false);
        });
    },

    handleLoadVideo() {
      if (this.formDataToSend.videoUrl != null) {
        this.handleDeleteVideo();
      }
      this.formDataToSend.video = document.querySelector(`input[name="video"]`).files[0];
      let data = new FormData();
      data.append("video", this.formDataToSend.video);
      this.$store.commit("templates/setLoading", true);
      templatesApi
        .templateVideoUpload(data)
        .then((res) => {
          this.formDataToSend.videoUrl = res.data.fileName;
          this.successUploadVideo = true;
          setTimeout(() => {
            this.successUploadVideo = false;
          }, 3000);
        })
        .catch((error) => {
          const { data } = error.response;
          if (data.errors) {
            this.$store.commit("templates/setValidationErrors", data.errors);
          }
          this.formDataToSend.video = null;
          this.formDataToSend.videoUrl = null;
          this.errorModal = true;
          this.errorModalText = "You can't upload Video. Error";
        })
        .finally(() => {
          this.$store.commit("templates/setLoading", false);
        });
    },
    handleDeleteInstruction(cleaner = true) {
      const data = {
        instruction: this.formDataToSend.fileUrl,
      };
      this.$store.commit("templates/setLoading", true);
      templatesApi
        .templateInstructionDelete(data)
        .then((res) => {})
        .catch((error) => {
          this.errorModal = true;
          this.errorModalText = "You can't delete images. Error";
        })
        .finally(() => {
          this.$store.commit("templates/setLoading", false);
        });
      if (cleaner) {
        this.formDataToSend.file = null;
        this.formDataToSend.fileUrl = null;
      }
    },
    handleDeleteSourceFile(index, cleaner = true) {
      if (this.formDataToSend.sizes[index].file_name != null) {
        const url = `?path=${this.formDataToSend.sizes[index].file_name}`;
        this.$store.commit("templates/setLoading", true);
        templatesApi
          .templateSourceFileDelete(url)
          .then((res) => {})
          .catch((error) => {
            this.errorModal = true;
            this.errorModalText = "You can't delete file. Error";
          })
          .finally(() => {
            this.$store.commit("templates/setLoading", false);
          });
        if (cleaner) {
          this.formDataToSend.sizes[index].file = null;
          this.formDataToSend.sizes[index].file_name = null;
        }
      }
    },
    handleDeleteVideo(cleaner = true) {
      const data = {
        video: this.formDataToSend.videoUrl,
      };
      this.$store.commit("templates/setLoading", true);
      templatesApi
        .templateVideoDelete(data)
        .then((res) => {})
        .catch((error) => {
          this.errorModal = true;
          this.errorModalText = "You can't delete video. Error";
        })
        .finally(() => {
          this.$store.commit("templates/setLoading", false);
        });
      if (cleaner) {
        this.formDataToSend.video = null;
        this.formDataToSend.videoUrl = null;
      }
    },
    setFormats(data) {
      const formats = data;
      for (let i = 0; formats.length > i; i++) {
        this.$set(this.formDataToSend.formats, formats[i].id, false);
      }
    },
    setColor(data) {
      const color = data;
      for (let i = 0; color.length > i; i++) {
        this.$set(this.formDataToSend.color, color[i].id, false);
      }
    },
    setTags(data) {
      const tags = data;
      for (let i = 0; tags.length > i; i++) {
        this.$set(this.formDataToSend.tags, tags[i].id, false);
      }
    },
    handleSearch(value) {
      this.search = value;
    },
    getCategoriesList() {
      this.$store.dispatch("categories/getCategoriesTree");
    },
    getStatuses() {
      this.$store.dispatch("references/getTemplatesStatuses");
    },
    getSizes() {
      this.$store.dispatch("references/getTemplatesSizesAll");
    },
    getTypes() {
      this.$store.dispatch("references/getTemplatesTypes");
    },
    getStyle() {
      this.$store.dispatch("references/getTemplatesStyleAll");
    },
    getFormats() {
      try {
        referencesApi.getTemplatesFormats().then((res) => {
          const data = res.data[0].data;
          this.$store.commit("references/setTemplatesFormatsList", data);
          this.setFormats(data);
        });
      } catch (error) {
        console.log(error);
      }
    },
    getColor() {
      try {
        referencesApi.getTemplatesColorAll().then((res) => {
          const data = res.data.items;
          this.$store.commit("references/setTemplatesColorList", data);
          this.setColor(data);
        });
      } catch (error) {
        console.log(error);
      }
    },
    getTags() {
      try {
        referencesApi.getTemplatesTagsAll().then((res) => {
          const data = res.data.items;
          this.$store.commit("references/setTemplatesTagsList", data);
          this.setTags(data);
        });
      } catch (error) {
        console.log(error);
      }
    },
    getFontsFilter(search) {
      this.search = search;
      const data = {
        search: search,
      };
      try {
        referencesApi.filterTemplatesFont(data).then((res) => {
          const data = res.data.items?.data;
          this.$store.commit("references/setTemplatesFontsList", data);
        });
      } catch (error) {
        console.log(error);
      }
    },
    setName(value) {
      this.formDataToSend.name = value.trim();
      this.setSeoName(value);
    },
    setSeoName(data) {
      const value = seoTransform(data);
      this.formDataToSend.seo_name = value;
      this.handleCheckSeoName();
    },
    handleCheckSeoName() {
      if (this.formDataToSend.seo_name.length > 0) {
        const url = `/${this.formDataToSend.seo_name.trim()}`;
        this.$store.dispatch("templates/templateCheckSeo", url);
      }
    },
    handleDeleteRow(index) {
      this.handleDeleteSourceFile(index);
      this.formDataToSend.sizes.splice(index, 1);
    },
    handleDeleteRowVideo(index) {
      this.formDataToSend.videos.splice(index, 1);
    },
    handleAddRow() {
      this.formDataToSend.sizes.push({
        name: "",
        size: null,
        src: "",
        file: null,
        file_name: null,
      });
    },
    handleAddRowVideo() {
      this.formDataToSend.videos.push({
        title: "",
        url: "",
        id: Math.floor(Math.random() * 10000),
      });
    },
    handleBackToList() {
      this.$router.push({
        path: ROUTE.TEMPLATES_LIST.replace(":page", 1),
      });
    },
    handleAddImage() {
      if (
        this.formDataToSend.img.length == 1 ||
        this.formDataToSend.img[this.formDataToSend.img.length - 1].file != null
      ) {
        this.formDataToSend.img.push({
          file: null,
          id: Math.floor(Math.random() * 100),
        });
        this.formDataToSend.imgUrl.push({
          url: null,
        });
      }
    },
    handleAddImagePreview() {
      if (
        this.formDataToSend.preview.length == 1 ||
        this.formDataToSend.preview.length == 0 ||
        this.formDataToSend.preview[this.formDataToSend.preview.length - 1].file != null ||
        this.formDataToSend.previewUrl[this.formDataToSend.previewUrl.length - 1].url != null
      ) {
        this.formDataToSend.preview.push({
          file: null,
          id: Math.floor(Math.random() * 10000),
        });
        this.formDataToSend.previewUrl.push({
          url: null,
          urlImg: null,
        });
      }
    },
    handleAddImageGif() {
      if (
        this.formDataToSend.gif.length == 1 ||
        this.formDataToSend.gif.length == 0 ||
        this.formDataToSend.gif[this.formDataToSend.gif.length - 1].file != null ||
        this.formDataToSend.gifUrl[this.formDataToSend.gifUrl.length - 1].url != null
      ) {
        this.formDataToSend.gif.push({
          file: null,
          id: Math.floor(Math.random() * 10000),
        });
        this.formDataToSend.gifUrl.push({
          url: null,
          urlGif: null,
        });
      }
    },
    handleAddImageSlider() {
      if (
        this.formDataToSend.slider.length == 1 ||
        this.formDataToSend.slider.length == 0 ||
        this.formDataToSend.slider[this.formDataToSend.slider.length - 1].file != null ||
        this.formDataToSend.sliderUrl[this.formDataToSend.sliderUrl.length - 1].url != null
      ) {
        this.formDataToSend.slider.push({
          file: null,
          id: Math.floor(Math.random() * 10000),
        });
        this.formDataToSend.sliderUrl.push({
          url: null,
          urlImg: null,
        });
      }
    },
    handleDeleteImage(index) {
      if (this.formDataToSend.imgUrl[index].url != null) {
        this.$store.commit("templates/setLoading", true);
        const data = {
          fileName: this.formDataToSend.imgUrl[index].url,
        };
        templatesApi
          .templateImagesDelete(data)
          .then((res) => {
            if (res.data.deleted) {
              this.formDataToSend.img.splice(index, 1);
              this.formDataToSend.imgUrl.splice(index, 1);
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.$store.commit("templates/setLoading", false);
          });
      } else {
        this.formDataToSend.img.splice(index, 1);
        this.formDataToSend.imgUrl.splice(index, 1);
      }
    },
    handleDeleteGif(index) {
      if (this.formDataToSend.gifUrl[index].url != null) {
        this.$store.commit("templates/setLoading", true);
        const data = {
          fileName: this.formDataToSend.gifUrl[index].url,
        };
        templatesApi
          .templateImagesDelete(data)
          .then((res) => {
            if (res.data.deleted) {
              this.formDataToSend.gif.splice(index, 1);
              this.formDataToSend.gifUrl.splice(index, 1);
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.$store.commit("templates/setLoading", false);
          });
      } else {
        this.formDataToSend.gif.splice(index, 1);
        this.formDataToSend.gifUrl.splice(index, 1);
      }
    },
    handleDeletePreview(index) {
      if (this.formDataToSend.previewUrl[index].url != null) {
        this.$store.commit("templates/setLoading", true);
        const url = `?fileName=${this.formDataToSend.previewUrl[index].url}`;
        templatesApi
          .templateImagesPreviewDelete(url)
          .then((res) => {
            if (res.data.deleted) {
              this.formDataToSend.preview.splice(index, 1);
              this.formDataToSend.previewUrl.splice(index, 1);
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.$store.commit("templates/setLoading", false);
          });
      } else {
        this.formDataToSend.preview.splice(index, 1);
        this.formDataToSend.previewUrl.splice(index, 1);
      }
    },
    handleDeleteSlider(index) {
      if (this.formDataToSend.sliderUrl[index].url != null) {
        this.$store.commit("templates/setLoading", true);
        const url = `?fileName=${this.formDataToSend.sliderUrl[index].url}`;
        templatesApi
          .templateImagesSliderDelete(url)
          .then((res) => {
            if (res.data.deleted) {
              this.formDataToSend.slider.splice(index, 1);
              this.formDataToSend.sliderUrl.splice(index, 1);
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.$store.commit("templates/setLoading", false);
          });
      } else {
        this.formDataToSend.slider.splice(index, 1);
        this.formDataToSend.sliderUrl.splice(index, 1);
      }
    },
    handleChangeImage(index) {
      this.$store.commit("templates/setLoading", true);
      const data = {
        fileName: this.formDataToSend.imgUrl[index].url,
      };
      templatesApi
        .templateImagesDelete(data)
        .then((res) => {
          if (res.data.deleted) {
            this.formDataToSend.img[index].file = null;
            this.formDataToSend.imgUrl[index].url = null;
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.$store.commit("templates/setLoading", false);
        });
    },
    handleChangeGif(index) {
      this.$store.commit("templates/setLoading", true);
      const data = {
        fileName: this.formDataToSend.gifUrl[index].url,
      };
      templatesApi
        .templateImagesDelete(data)
        .then((res) => {
          if (res.data.deleted) {
            this.formDataToSend.gif[index].file = null;
            this.formDataToSend.gifUrl[index].url = null;
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.$store.commit("templates/setLoading", false);
        });
    },
    handleChangePreview(index) {
      this.$store.commit("templates/setLoading", true);
      const url = `?fileName=${this.formDataToSend.previewUrl[index].url}`;
      templatesApi
        .templateImagesPreviewDelete(url)
        .then((res) => {
          if (res.data.deleted) {
            this.formDataToSend.preview[index].file = null;
            this.formDataToSend.previewUrl[index].url = null;
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.$store.commit("templates/setLoading", false);
        });
    },
    handleChangeSlider(index) {
      this.$store.commit("templates/setLoading", true);
      const url = `?fileName=${this.formDataToSend.sliderUrl[index].url}`;
      templatesApi
        .templateImagesSliderDelete(url)
        .then((res) => {
          if (res.data.deleted) {
            this.formDataToSend.slider[index].file = null;
            this.formDataToSend.sliderUrl[index].url = null;
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.$store.commit("templates/setLoading", false);
        });
    },
    getImageSizes() {
      templatesApi
        .templateImageSizes()
        .then((res) => {
          this.imageSize = res.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/.mx-datepicker {
  width: 100%;
}
.checkbox-container__item {
  padding: 5px 0;
}

.checkbox-container {
  @include scrollBar;

  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 20px;

  &_margin {
    margin-top: 20px;
  }
}

/deep/.textarea-input {
  textarea {
    height: 240px;
    resize: vertical;
  }
}
/deep/.preview-container {
  margin: 0;
}

.form-create {
  &__images {
    position: relative;
    display: flex;
    flex-wrap: wrap;
  }

  &__file-ftp {
    max-width: 500px;
  }

  &__btn {
    padding-bottom: 20px;
  }

  &__image {
    position: relative;
    width: 220px;
    height: 220px;
    margin: 0 30px 45px 0;
    border: 1px solid $mainDisabled;
  }

  &__delete-image {
    position: absolute;
    top: -19px;
    right: -26px;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
  }

  &__add-image {
    position: absolute;
    top: -19px;
    right: -8px;
    cursor: pointer;
    transform: rotate(45deg);

    svg {
      path {
        fill: $mainSecondColor;
      }
    }

    &:hover {
      opacity: 0.7;
    }
  }

  &__image-load {
    width: 220px;
    height: 220px;
    object-fit: cover;
  }

  &__change-image {
    position: absolute;
    bottom: -40px;
    left: 44%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    background: $mainColor;
    border-radius: 50px;
    cursor: pointer;

    svg {
      path {
        fill: $g-color-white;
      }
    }

    &:hover {
      opacity: 0.7;
    }
  }
}
.upload-instruction {
  display: flex;

  &__button {
    margin-right: 20px;
  }
}

.error-img {
  font-size: 24px;
  color: red;
  text-align: center;
  margin: auto;
}
</style>
